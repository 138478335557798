.button-alert {
  margin: 1rem;
  display: flex;
  justify-content: center;
}

.button-alert:hover,
.button-alert:active,
.button-alert:focus {
  background-color: #20202a;
  border-color: #20202a;
  box-shadow: none;
}

.centered-icons {
  margin-top: 0.4rem;
}

.alert-container {
  // background-color: #35343a;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
  padding-bottom: 1rem;
}

.alert-header {
  background-color: #14141b;
  border-bottom: 3px solid #0e0e12;
  padding: 0.7rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.alert-text {
  display: inline;
  margin-left: 0.5rem;
}

.bg-button-alert-red {
  background-color: #ff2828;
  border-color: #ff2828;
  &:hover {
    background-color: #ff3e3e;
    border-color: #ff3e3e;
  }
}

.alert-images {
  padding: 0;
  margin: 0;
  height: 2rem;
  width: 2rem;
  align-self: center;
  object-fit: contain;
}

.buttons-alerts-container {
  min-width: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons-alerts-container-modal {
  min-width: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 25rem;
}
