.custom-modal-staff {
  height: 100%;
  width: 100%;
}

.map-container-staff {
  height: 60%;
  width: 60%;
  border-radius: 20px 20px 0px 0px;
  margin-bottom: 5rem;
  @media (max-width: 768px) {
    height: 80%;
    width: 95%;
  }
}

.map-header-staff {
  height: 3.5rem;
  width: 100%;
  border-radius: 15px 15px 0px 0px;
  background-color: #36373c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.location-modal-exit-icon {
  color: white;
  cursor: pointer;
}

$marker-size: 60px;

.location-device-marker {
  background-color: rgba(82, 168, 255, 0.3);
  border-radius: 50%;
  height: $marker-size;
  width: $marker-size;
  position: absolute;
  left: -$marker-size / 2;
  top: -$marker-size / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  // the latter is to center the marker over the coordinate
}

$marker-child-size: $marker-size / 3;

.location-device-marker-child {
  background-color: #52a8ff;
  border-radius: 50%;
  height: $marker-child-size;
  width: $marker-child-size;
}
