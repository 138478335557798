.staff-container {
  background-color: #1d232e;
  margin: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.button-group {
  min-width: 5.5rem;
  min-height: 2.7rem;
  //max-height: 2.7rem;
}

.input-search {
  border-radius: 5px;
  height: 2.5rem;
  background: #393941;
  border: 2px solid #44444c;
  color: white;
  padding-left: 0.5rem;
  &:focus {
    outline: none;
  }
}

.all-button {
  background-color: #5b99e3;
  border: none;
  width: 8rem;
  height: 2.7rem;
}

.buttons-right {
  margin: 1rem;
  @media (max-width: 555px) {
    margin-bottom: 1rem;
    margin-right: 0.5rem;
  }
}

.margin-icon {
  margin-right: 1rem;
}

.margin-icon-staff {
  margin-right: 0.2rem;
}

.add-icon-staff {
  height: 2.7rem;
  width: 2.7rem;
}

.staff-icon-animated {
  color: white;
  cursor: pointer;
  transition: all 0.05s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: 575px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.staff-icon {
  color: white;
}

p {
  margin: 0;
  padding: 0;
}

.word-break {
  word-break: break-word;
}

.section-width-1 {
  min-width: 7%;
  max-width: 7%;
  @media (max-width: 575px) {
    min-width: auto;
    max-width: 100%;
    margin: 0.5rem;
  }
}

.section-width-2 {
  width: 20%;
  @media (max-width: 575px) {
    width: auto;
    text-align: center;
    margin: 0.5rem;
  }
}

.section-width-3 {
  width: 15%;
  @media (max-width: 575px) {
    width: auto;
    margin: 0.5rem;
  }
}

.section-width-4 {
  width: 15%;
  @media (max-width: 575px) {
    width: auto;
    margin: 0.5rem;
  }
}

.section-width-5 {
  width: 18%;
  margin-right: 0.5rem;
  @media (max-width: 575px) {
    width: auto;
    margin: 0.5rem;
  }
}

.section-width-6 {
  width: 15%;
  @media (max-width: 575px) {
    width: auto;
    margin: 0.5rem;
  }
}

.section-width-7 {
  width: 7rem;
  margin-right: 0.5rem;
  @media (max-width: 575px) {
    width: auto;
    margin: 0.5rem;
  }
}

.user-information-container {
  border: 1px solid rgba(143, 143, 168, 0.53);
  border-top: none;
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }
}

.page-container-staff {
  min-height: 49rem;
  @media (max-width: 768px) {
    overflow-x: scroll;
  }
  @media (max-width: 552px) {
    margin-top: 4rem;
  }
}

.section-group-1 {
  width: 20%;
}

.section-group-2 {
  width: 20%;
}

.section-group-3 {
  width: 48%;
}

.centered {
  margin: auto;
}

.filters-field {
  margin-top: 0.5rem;
}

.content-width-mb {
  width: max-content;
}

.wrap-responsive {
  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
}

.wrap {
  @media (max-width: 555px) {
    flex-wrap: wrap;
    justify-content: center !important;
    margin-left: 0px !important;
  }
}

.staff-menu {
  @media (max-width: 555px) {
    align-items: center;
  }
}

.ms-5 {
  @media (max-width: 700px) {
    margin-left: 0.5rem !important;
  }
  @media (max-width: 555px) {
    margin-left: 0.5rem !important;
    margin-top: 0.5rem;
  }
}

.me-2 {
  margin-top: 0.4rem;
}

.mg-top-2 {
  margin-top: 2rem;
}

.mg-top {
  margin-top: .2rem;
}

.mg-bot {
  margin-bottom: 1rem;
}

.mg-lt {
  margin-left: 0.5rem;
}

.label-staff {
  margin-right: 0.3rem;
}

.close-group-content-button {
  margin-right: -1rem;
  margin-top: -0.3rem;
}

.flex-dir-column {
  @media(max-width: 555px) {
    flex-direction: column;
  }
}

.justify-content-custom {
  @media(max-width: 633px) {
    justify-content: center;
  }
  justify-content: start;
}

.center-text {
  @media(max-width: 555px) {
    text-align: center;
  }
}

.flex-custom-row {
  @media(min-width: 1160px) {
    flex-direction: row!important;
  }
}

.select-custom-width {
  min-width: 75%!important;
  @media(max-width: 575px) {
    min-width: 100%!important;
  }
}

.input-select-custom {
  background-color: #38373d;
  outline: none;
  border-radius: 4px;
  border: 1.5px solid #505050;
  margin: 0;
  padding: 0;
  padding-left: 0.8rem;
  color: #51fffe;
  font-size: 1rem;
  height: 2.2rem;
}