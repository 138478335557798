.calendar-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #191923;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  height: 3rem;
  border-radius: 6px 0px 0px 6px;
}

.alert-page-container {
  min-height: 90%;
  @media (max-width: 768px) {
    overflow-x: scroll;
  }
  @media (max-width: 552px) {
    margin-top: 4rem;
  }
}

.calendar-input {
  background-color: #393941;
  outline: none;
  border: none;
  border-radius: 0px 6px 6px 0px;
  color: white;
  max-width: 10rem;
  height: 3rem;
  padding-left: 1rem;
}

.alert-width-section-1 {
  width: 23%;
  @media (max-width: 575px) {
    width: auto;
  }
}
.alert-width-section-2 {
  width: 23%;
  @media (max-width: 575px) {
    width: auto;
  }
}
.alert-width-section-3 {
  width: 23%;
  @media (max-width: 575px) {
    width: auto;
  }
}
.alert-width-section-4 {
  width: 23%;
  @media (max-width: 575px) {
    width: auto;
  }
}
.alert-width-section-5 {
  width: 10rem;
  @media (max-width: 575px) {
    width: auto;
  }
}

.alert-information {
  border: 1px solid rgba(143, 143, 168, 0.53);
  border-top: none;
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }
}

.page-container-alert {
  @media (max-width: 768px) {
    width: 80rem;
    overflow-x: scroll;
  }
}

.alert-item-setting-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
}

.recipients-selector {
  width: 10rem;
  margin: 0;
  padding: 0;
  margin-left: 2rem;
  padding-left: 0.5rem;
}

.alert-icon-setting {
  height: auto;
  width: 1rem;
  object-fit: contain;
  margin: 0;
  padding: 0;
}

.alert-type-title-settings {
  width: 11rem;
}

.social-media-item-settings {
  width: 9rem;
}

.social-media-settings-container {
  width: 30rem;
  max-height: 12rem;
  min-height: 12rem;
}

.social-media-icon {
  height: 1.2rem;
  width: 1.2rem;
  object-fit: contain;
  margin: 0;
  padding: 0;
}

.new-alert-type-container {
  min-height: 10rem;
}

.is-defualt-spinner {
  color: white;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: -0.3rem;
}

.alert-type-action-item-container {
  border-bottom: 2px solid rgba(143, 143, 168, 0.53);
  padding-bottom: 1rem;
  padding-top: 0.1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.delete-action-button {
  min-width: 2rem;
}

.mg-r {
  margin-right: 1rem;
  @media (max-width: 575px) {
    margin-right: 0rem!important;
  }
}

.mg-r-xs {
  margin-right: 0.5rem;
}

.d-xl-custom-flex {
  @media (min-width: 1260px) {
    display: flex!important;
  }
}

.d-md-custom-flex {
  @media (min-width: 1050px) {
    display: flex!important;
  }
  @media (max-width: 575px) {
    margin-top: 1rem;
  }
}

.justify-content-custom {
  @media (min-width: 1260px) {
    justify-content: end;
  }
  @media (max-width: 1260px) {
    justify-content: center;
  }
  @media (max-width: 575px) {
    flex-wrap: wrap;
  }
}

.filters-content {
  @media (max-width: 575px) {
    flex-wrap: wrap;
  }
}

.pe-4-custom {
  padding-right: 1.5rem!important;
  @media (max-width: 555px) {
    padding-right: 0rem!important;
    margin-left: 5rem;
    margin-bottom: 0.5rem;
  }
}
.custom-width {
  min-width: 100%;
}

.p-information {
  @media (max-width: 575px) {
    p{
      margin-bottom: 1rem;
    }
    flex-direction: column;
    align-items: center;
  }
}

.custom-p-responsive {
  @media (max-width: 575px) {
    display: flex;
    align-items: center;
  }
}

.alert-responsive-title {
  margin: auto;
}

.custom-me {
  margin-right: 9rem!important;
}