.toast-dialog-container {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  p {
    text-align: center;
  }
  .buttons-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}
.toast-dialog-container-alert {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
  margin-left: 0.7rem;
  margin-right: 0.5rem;
}
.toast-dialog-position-alert {
  background-color: #ffffff;
  color: black;
  position: relative;
  top: 10rem;
}
.animation-alert {
  animation: myAnim 1900ms ease 0s infinite normal forwards;
  border-radius: 10px;
  margin-right: 5rem;
  margin-left: 4rem;
}
.notification-icon {
  color: black;
}
@keyframes myAnim {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}