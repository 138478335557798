.parents-container {
    min-height: 24rem;
    max-height: 18rem;
    background-color: #35343a;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}