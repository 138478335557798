.pagination-prev {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  border: 2px solid #191923;
  border-radius: 6px 0px 0px 6px;
  background-color: #2c2d32;
  z-index: 1;
  cursor: pointer;
}

.pagination-next {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  border: 2px solid #191923;
  border-radius: 0px 6px 6px 0px;
  background-color: #2c2d32;
  z-index: 1;
  cursor: pointer;
}

.pagination-item {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  border: 2px solid #191923;
  background-color: #2c2d32;
  margin-left: -2.5px;
  margin-right: -2.5px;
  z-index: 2;
  cursor: pointer;
}

.pagination-selected {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  border: 2px solid #302db1;
  background-color: #433ebc;
  margin-left: -2.5px;
  margin-right: -2.5px;
  z-index: 3;
  cursor: pointer;
}

.pagination-text {
  padding: 0;
  margin: 0;
  color: white;
}

//prevents the text from being selected, to give the appearance of a button
.noselect-text {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
