.sidebar {
  .logo {
    background-color: #24242c;
    // margin-bottom: -8px;
    max-height: 57px; // to fit the rest of the nav bar height

    img {
      // max-height: 1em;
    }
  }
}
