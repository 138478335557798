.staffNumberContainer {
  width: 31.5%;
  border-radius: 10px;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.bg-totalStaff {
  background: rgb(47, 51, 125);
  background: linear-gradient(
    180deg,
    rgba(47, 51, 125, 1) 0%,
    rgba(73, 79, 179, 1) 90%
  );
}

.bg-OnSiteStaff {
  background: rgb(92, 133, 179);
  background: linear-gradient(
    180deg,
    rgba(92, 133, 179, 1) 0%,
    rgba(95, 157, 227, 1) 90%
  );
}

.bg-OffSiteStaff {
  background: rgba(128, 47, 46, 1);
  background: linear-gradient(
    180deg,
    rgba(128, 47, 46, 1) 0%,
    rgba(185, 67, 67, 1) 90%);
}

.StaffNumbers {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0;
}

.StaffNumbersLabel {
  font-size: 1.3rem;
}
