.settings-container {
    width: 40rem;
    min-height: 20rem;
    background: white;
    border-radius: 6px;
    border: 2px solid black;
    background-color: #24242c;
    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }

.settings-body{
    min-height: 31rem;
    max-height: 31rem;
    background-color: #35343a;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.emergency-number-box{
  background-color: #38373d;
  outline: none;
  color: white;
  border-radius: 4px;
  border: 1.5px solid #505050;
  margin: 0;
  padding: 0;
  padding-left: 0.8rem;
  color: #51fffe;
  font-size: 1.2rem;
  height: 2.2rem;
}

.settings-container-numbers {
    min-height: 30rem;
    max-height: 30rem;
    background-color: #35343a;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    @media (max-width: 768px) {
        min-height: calc(100vh - 12rem);
    }
}

.settings-container-spinner {
  min-height: 30rem;
  max-height: 30rem;
}

.subtitle-settings{
  background-color: #35343a;
}

.w-pers{
  width: 94%!important;
}

.settings-header {
    background-color: #20202a;
    border-bottom: 7px solid #35343a;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.section-field-1 {
    width: 50%;
}

.section-field-2 {
    width: 50%;
}