.text-editor-new-template {
  height: 50%;
  width: 100%;
  color: white;
}

.new-template-header {
  background-color: #20202a;
  border-bottom: 3px solid #35343a;
  padding: 0.7rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.template-select {
  background-color: #20202a;
  color: white;
  border: 2px solid #44454a;
  width: 50%;
  &:focus {
    border: none;
    outline: none;
  }
}

.text-viewer-new-message {
  border: 2px solid #44454a;
  border-radius: 5px;
}

.mic-button-container {
  height: 2.7rem;
  width: 2.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.stop-button-container {
  height: 2.7rem;
  width: 2.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #24242c;
  border: 4px solid #ff3b30;
  &:hover {
    height: 2.7rem;
    width: 2.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #24242c;
    border: 4px solid #ff3b30;
  }
}

.icon-stop-button {
  background-color: #ff3b30;
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 2px;
  &:hover {
    background-color: #ff3b30;
    height: 0.8rem;
    width: 0.8rem;
    border-radius: 2px;
  }
}

@import "react-h5-audio-player/lib/styles.css";

.audio-player-container {
  width: 20rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.audio-time-text {
  width: 7rem;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 1.4rem;
}

.rhap_container {
  display: flex;
  justify-items: center;
  padding: 0.25em 0.75em;
  border-radius: 20px;
  background-color: #24242c;
  border: 4px solid #35343a;
  padding: 1rem;
}

.rhap_current-time {
  color: white;
}

.rhap_total-time {
  color: white;
}

.rhap_play-status--playing {
  color: white;
}

.rhap_play-status--paused {
  color: white;
}

.recording-status {
  height: 1rem;
  width: 1rem;
  background-color: #ff3b30;
  border-radius: 50%;
  animation: blinker 0.7s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.audio-recorder-button-save {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.template-name-width {
  width: 12rem;
}

.drop-zone-container {
  border: 2px dashed #52a8ff;
  border-radius: 4px;
  padding: 1rem;
  width: 25rem;
}
