.alert-image {
  height: 9rem !important;
  width: 9rem !important;
  border: 2px solid rgb(180, 180, 8);
  border-radius: 50%;
  font-size: 2px;
  @media (max-width: 768px) {
    height: 8rem;
    width: 8rem;
  }
}
.alert-image-shooter {
  height: 4rem;
  width: 5rem;
  font-size: 2px;
  color: rgb(180, 180, 8);
}

.yellow-text {
  color: rgb(180, 180, 8);
  font-size: 1rem;
}

.alert-image-none {
  height: 8rem;
  width: 8rem;
  border: 2px solid #1d232e;
  border-radius: 50%;
  font-size: 2px;
}
.check-alert {
  background-color: #14b386;
  border-radius: 50%;
}

.dismiss-button {
  height: 3rem;
  width: 7rem;
  border-radius: 8px;
  margin-bottom: -2.5rem;
}

.content-custom-alert {
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 575px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
}

.content-center-xs {
  @media (max-width: 575px) {
    align-self: end;
  }
  @media (max-width: 475px) {
    align-self: center;
  }
}
