.navBarShadow {
  -webkit-box-shadow: 0px 3px 2px -0.5px #000000;
  box-shadow: 0px 1.5px 2px -0.5px #000000;
}

.navbar{
  width: 100%;
}

.icon-white {
  color: white;
  cursor: pointer;
}

.icon-bell{
  margin-top: 0.5rem;
}

.alert-button {
  color: #51fffe;
}
.current-alert-sign{
  width:24% ;
}
.current-alert-sign-text{
  width: 95%;
  //font-size: 1.6rem ;
  text-transform: capitalize;
  display: flex;
  align-items: start;
  justify-content: center;
}

.close-alert-button {
  width:2rem;
  height: 2rem;
  text-align:center;
  border-width: 2px;
  //padding-bottom: 1rem;
  // border-color:white;
  // color: white;
  background-color:transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 0px;


}
.close-alert-button:hover{
  background-color: transparent;
  // border-color:white;
   color: black;
}
.sidebar-button-nav {
  &:active {
    background-color: #3b3b47;
    border-radius: 3px;
  }
}

.is-defualt-spinner {
  color: white;
  height: 1.5rem;
  width: 1.5rem;

}

.user-menu {
  height: 2rem;
  width: 2rem;
  background-color: red;
  position: absolute;
}

.user-utils{
  margin: auto;
}

.custom-header{
    position: sticky;
    top: 0;
    z-index: 1029;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    min-height: 4rem;
}