.perimeters-container {
  background-color: #1d232e;
  padding-top: 1rem;
  margin-bottom: 0.5rem;
}

.add-icon {
  color: #51fffe;
}

.add-icon-container {
  background-color: #433ebc;
  border: none;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #4e49dd;
  }
}

.name-text {
  margin: 0;
  padding: 0;
  margin-left: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.7rem;
}

.perimeters {
  border: 1px solid rgba(143, 143, 168, 0.53);
  border-top: none;
}

.name-width {
  width: 25%;
  @media (max-width: 768px) {
    width: 50%;
  }
}

.address-width {
  align-self: flex-start;
}

.perimeters-icon {
  color: white;
  margin-right: 0.7rem;
  cursor: pointer;
  transition: all 0.05s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}

.map-container {
  background: rosybrown;
  height: 30rem;
}

.vision-icon {
  object-fit: contain;
  height: 20px;
  width: 20px;
}
