.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropdown-item {
  color: #ddd!important;
}
.dropdown-item:focus, .dropdown-item:hover{
  background-color: #1e2125!important;
  color: #ddd!important;
}

.input-select-custom {
  background-color: #38373d;
  outline: none;
  border-radius: 4px;
  border: 1.5px solid #505050;
  margin: 0;
  padding: 0;
  padding-left: 0.8rem;
  color: #51fffe;
  font-size: 1rem;
  height: 2.2rem;
}

.green-check .form-check-input:checked {
  background-color: green!important;
  border-color: green!important;
}
.green-check .form-check-input:focus{
  box-shadow: 0 0 0 .25rem #00800048;
}