.bg-dark1 {
  background-color: #2c2d32;
}

.bg-dark2 {
  background-color: #24242c;
}

.bg-dark3 {
  background-color: #20202a;
}

.bg-dark4 {
  background-color: #36373c;
}

.bg-main {
  background-color: #0b0b17;
}

.text-color {
  color: #51fffe;
}

.text-color2 {
  color: #b6fbfe;
}

.default-layout {
  display: flex;
  overflow-y: hidden;
  height: 100%;
}

.main-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: none;
  flex: 1;
}

p {
  padding: 0;
  margin: 0;
}

body>#root>div {
  height: 100vh;
}

*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #7e7e7e;
}

*::-webkit-scrollbar-thumb {
  background-color: #20202a;
  border-radius: 10px;
  border: 3px solid #35343a;
}

.over-flow-auto {
  overflow-y: auto;
}

.over-flow-hidden {
  overflow-y: hidden;
}

// multiselect dropdown
.multiselect-container .optionListContainer .optionContainer .option {
  color: #999;
}

.multiselect-container .optionListContainer .optionContainer .option.highlightOption {
  color: #fff !important;
}

.multiSelectContainer li:hover {
  color: #fff !important;
}



@media only screen and (min-width: 767px) and (max-width: 1100px) {

  .table-size {
    width: 1000px !important;
  }

  .app-user-table-size {
    width: 1100px !important;
  }

  .overflowX {
    overflow-x: auto;
  }
}

.w-13 {
  width: 13% !important;
}


.multiselect-container .optionListContainer .optionContainer .option {
  color: #999;
  background-color: #0B0B17 !important;
  border: 0px !important;
}

.avatar-img {
  width: 100%;
  height: 100% !important;
  border-radius: 50em;
  object-fit: cover !important;
}