.lowBatteryContainer {
  background-color: #1d232e;
  min-height: 100%;
  width: 49%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.overFlow {
  overflow-y: auto;
}

.low-container {
  display: flex;
  flex: 1;
}
