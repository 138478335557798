$marker-size: 45px;

.location-device-marker {
  background-color: rgba(82, 168, 255, 0.3);
  border-radius: 50%;
  height: $marker-size;
  width: $marker-size;
  position: absolute;
  left: -$marker-size / 2;
  top: -$marker-size / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  // the latter is to center the marker over the coordinate
}

$marker-child-size: $marker-size / 3;

.location-device-marker-child {
  background-color: #52a8ff;
  border-radius: 50%;
  height: $marker-child-size;
  width: $marker-child-size;
}

.number-perimeter-marker-container {
  height: $marker-size;
  width: $marker-size;
  border-radius: 50%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -$marker-size / 2;
  top: -$marker-size / 2;
}
