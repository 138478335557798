.section-width-users-1 {
    min-width: 7%;
    max-width: 7%;
    @media (max-width: 575px) {
      min-width: auto;
      max-width: 100%;
      margin: 0.5rem;
    }
  }
  
  .section-width-users-2 {
    width: 25%;
    @media (max-width: 575px) {
      width: auto;
      text-align: center;
      margin: 0.5rem;
    }
  }
  
  .section-width-users-3 {
    width: 30%;
    @media (max-width: 575px) {
      width: auto;
      margin: 0.5rem;
    }
  }
  
  .section-width-users-4 {
    width: 20%;
    @media (max-width: 575px) {
      width: auto;
      margin: 0.5rem;
    }
  }
  
  .section-width-users-5 {
    width: 10%;
    margin-right: 1rem;
    @media (max-width: 575px) {
      width: auto;
      margin: 0.5rem;
    }
  }
  