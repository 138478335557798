.spinner{
    text-align: center;
    margin-top: 10rem;
}

.section-width-parents-1 {
    min-width: 20%;
    max-width: 20%;
    @media (max-width: 575px) {
      min-width: auto;
      max-width: 100%;
      margin: 0.5rem;
    }
  }

.section-width-parents-custom {
  min-width: 15%;
  max-width: 15%;
  @media (max-width: 575px) {
    min-width: auto;
    max-width: 100%;
    margin: 0.5rem;
  }
}
  
  .section-width-parents-2 {
    width: 25%;
    @media (max-width: 575px) {
      width: auto;
      text-align: center;
      margin: 0.5rem;
    }
  }
  
  .section-width-parents-3 {
    width: 15%;
    @media (max-width: 575px) {
      width: auto;
      margin: 0.5rem;
    }
  }
  
  .section-width-parents-4 {
    width: 20%;
    @media (max-width: 575px) {
      width: auto;
      margin: 0.5rem;
    }
  }