.battery-level {
  min-width: 8rem;
}

.header-container {
  border: 2px solid rgba(143, 143, 168, 0.53);
  background-color: #2e2e36;
  border-bottom: 3px solid rgba(189, 189, 221, 0.5);
}

.all-button {
  background-color: #5b99e3;
  border: none;
  width: 5rem;
  @media (max-width: 768px) {
  margin-right: 1px;
  width: auto !important
  }
}

.icons-low-battery-device {
  color: white;
  height: 2rem;
}

.low-battery-devices-header {
  background-color: #181d26;
}

.divider-container {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  @media (max-width: 768px) {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}

.spinner {
  text-align: center;
}

.lowbattery-title {
  margin-top: 0.5rem;
}

.spinner-send-message {
  height: 1.5rem;
  width: 1.5rem;
}
