.toast-dialog-container {
  p {
    text-align: center;
  }
  .buttons-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }
  .button-dialog {
    height: 2rem;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
  }
}
.toast-dialog-position {
  background-color: #4f5d73;
  color: white;
  position: relative;
  top: 10rem;
}
